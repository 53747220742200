// -----------------------------------------------------
// Start :: Styles for Header Component
// -----------------------------------------------------
import { css } from '@emotion/core';
export const header = css`
  background: #fff;
  /* position: fixed; */
  width: 100%;
  padding: 24px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
  top: 0;
  z-index: 1;
  height: auto;
  line-height: 0;

  .headerRow {
    flex-flow: row nowrap;
  }

  h3.ant-typography {
    font-weight: 600;
    font-size: 12px;
    margin-top: 10px;
    margin-left: 10px;
    color: #2453a6;
  }

  .appName {
    text-align: left;
    font: Medium 18px/22px Montserrat;
    letter-spacing: 0px;
    color: #2453a6;
    text-transform: uppercase;
    opacity: 1;
  }

  @media (max-width: 1024px) {
    padding: 15px 10px !important;
  }
`;

export const headerButton = css`
  display: flex;
  flex-direction: row-reverse;
`;

export const loginBtn = css`
  border: 1px solid #286ce2;
  opacity: 0.5;

  font-size: 14px;
  margin-right: 10px;
  font-family: lato;

  letter-spacing: 0.9px;
  color: #286ce2;
  opacity: 1;
`;

export const registerBtn = css`
  background: #286ce2 0% 0% no-repeat padding-box;
  opacity: 1;

  text-align: left;
  font-size: 14px;
  font-family: lato;
  letter-spacing: 0.9px;
  color: #ffffff;
  opacity: 1;
`;

export const logo = css`
  a {
    display: flex;
  }
  @media screen and (max-width: 1024px) {
    img {
      width: auto;
      height: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    img {
      width: auto;
      height: 100%;
    }
  }
  @media screen and (max-width: 480px) {
    img {
      width: 370px;
      height: 100%;
    }
  }
`;

export const iconsContainer = css`
  display: flex;
  .nav-icon {
    font-size: 25px;
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #0079c2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    border: 1px solid #0079c2;
    &:hover {
      background: #fff;
      color: #0079c2;
    }
  }

  span.username {
    line-height: 3;
    text-align: left;
    letter-spacing: -0.4px;
    color: #666666;
    opacity: 1;
    text-transform: capitalize;
  }
`;
export const menuBar = css`
  line-height: 25px;
  text-align: center;
  font-weight: 400;
  margin-bottom: 5px;
`;

export const userDropDownMenuItems = css`
  a {
    color: #0078ab;
    font-size: 16px;
    text-decoration: none;
    padding: 12px;
  }
  a:hover,
  a:active {
    color: #1890ff;
  }
  .ant-dropdown-menu-item:hover {
    background-color: white;
  }
  .ant-dropdown-menu-item {
    margin-left: 16px;
  }
  li.ant-dropdown-menu-item.loggedInUser {
    font-weight: 300;
    text-transform: capitalize;
    font-size: 20px;
    margin: 16px;
    cursor: default;
  }
`;

export const userDropDownMenuCSS = {
  width: '300px',
  paddingTop: '10px',
};

// -----------------------------------------------------
// End :: Styles for Header Component
// -----------------------------------------------------
