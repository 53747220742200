import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ProgressBar from './../components/ProgressBar';

const LazyLoginModule = lazy(() => import('./Login/Login'));
const LazyForceResetPasswordModule = lazy(() => import('./ForceResetPassword/ForceResetPassword'));
const LazySetupSecurityQuestionModule = lazy(() => import('./SetupSecurityQuestion/SetupSecurityQuestion'));
const LazySetupCompletedModule = lazy(() => import('./SetupCompleted/SetupCompleted'));
const LazyForgotUsername = lazy(() => import('./ForgotUsername/ForgotUsername'));
const LazyForgotPasswordModule = lazy(() => import('./ForgotPassword/ForgotPassword'));

export default function AppRoutes() {
  return (
    <Suspense fallback={<ProgressBar />}>
      <Switch>
        <Route exact path="/forgot-username">
          <LazyForgotUsername />
        </Route>
        <Route exact path="/forgot-password">
          <LazyForgotPasswordModule />
        </Route>
        <Route exact path="/reset/password">
          <LazyForceResetPasswordModule />
        </Route>
        <Route exact path="/setup/security">
          <LazySetupSecurityQuestionModule />
        </Route>
        <Route exact path="/setup/completed">
          <LazySetupCompletedModule />
        </Route>
        <Route exact path="/">
          <LazyLoginModule />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Suspense>
  );
}
